import { ToastOptions } from "react-toastify";

export type Review = {
    name: string;
    rating: number;
    review: string;
  };

  export const toastDefaultOptions: ToastOptions = {
    position: 'top-center',
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    theme: 'colored',
  };
  
  export const reviews: Review[] = [
    {
      name: "John Doe",
      rating: 2,
      review: "Lorem ipsum dolor sit amet, consectetur adipiscing elit."
    },
    {
      name: "Jane Smith",
      rating: 3,
      review: "Vivamus luctus urna sed urna ultricies ac tempor dui sagittis."
    },
    {
      name: "Alice Johnson",
      rating: 1,
      review: "In condimentum facilisis porta."
    },
    {
      name: "Bob Brown",
      rating: 5,
      review: "Sed nec diam eu diam mattis viverra."
    },
    {
      name: "Carol White",
      rating: 5,
      review: "Nulla fringilla, orci ac euismod semper."
    }
  ];
  