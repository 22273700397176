"use client";

import { useEffect, useReducer, useRef, useState } from "react";

export default function AccordionItem({
	title,
	children,
	number,
	eClass,
}: {
	title: string;
	children: React.ReactNode;
	number: string;
	eClass?: string;
}) {
	const [isOpen, setIsOpen] = useState<boolean>(false);

	return (
		<div
			className={`w-[426px] min-h-[68px] border-[2.23px] ${
				isOpen ? "border-none bg-squeeze-pink" : "border-black"
			} rounded-[16.57px] relative`}
		>
			<div
				className={`flex flex-col transition ease-in-out `}
				onMouseEnter={() => {
					setIsOpen(true);
				}}
				onMouseLeave={() => {
					setIsOpen(false);
				}}
			>
				<div className={` `}>
					<div className='relative  w-full h-[68px] '>
						<p
							className={`absolute top-[13px] left-[13px] font-poppins text-[18.47px] ${
								isOpen ? "text-white" : "text-squeeze-pink"
							}`}
						>
							{number}
						</p>
						<p
							className={`${
								isOpen ? "opacity-0" : "opacity-100"
							} transition-opacity duration-300 absolute bottom-[8px] right-[25px] text-[22px] font-poppinsM text-squeeze-black`}
						>
							{title}
						</p>
					</div>
					<div className='max-laptop:col-span-2 lg:col-span-2 '></div>
				</div>

				<div
					className={`transition-max-height overflow-hidden  h-fit duration-300 ease-in-out  -mt-[14px]  ${
						isOpen
							? `${number === "02" ? "max-h-[100px]" : "max-h-[70px]"}`
							: "max-h-0"
					} w-full`}
				>
					<div
						className={`${
							number === "02" ? "h-[100px]" : "h-[70px]"
						} w-[420px] relative `}
					>
						<p className='absolute top-0 left-[63px] text-[20px] font-roboto text-white leading-[103.5%] -tracking-[2%]'>
							{children}
						</p>
					</div>
				</div>
			</div>
		</div>
	);
}
