import React, { useState } from "react";
import SingleEye from "./SingleEye";
import HeadingTextAnimation from "components/HeadingTextAnimation";

const EyeSection = ({ isVisible }: { isVisible: boolean }) => {
	return (
		<div className='h-[1172px] relative flex flex-col items-center justify-center widthClass overflow-x-clip'>
			<div className='widthClass h-[1172px]  '>
				<div className='relative text-white h- widthClass '>
					<div className='-space-y-[12px] absolute top-[460px] left-[450px] flex flex-col items-center justify-center'>
						<p>
							<HeadingTextAnimation
								text='Look at us'
								extraClass='uppercase font-poppinsB text-[90px]'
								idx='1'
								isVisible={isVisible}
							/>
						</p>

						<div className='flex w-fit h-fit text-[80px] text-white font-poppinsL'>
							<p>
								<HeadingTextAnimation
									text={"being all "}
									isVisible={isVisible}
									idx={"2"}
									extraClass='mr-[28px] '
								/>
							</p>
							<p>
								<HeadingTextAnimation
									text={"bark"}
									isVisible={isVisible}
									idx={"2"}
									extraClass='text-squeeze-yellow '
								/>
							</p>
							<p>
								<HeadingTextAnimation
									text={","}
									isVisible={isVisible}
									idx={"2"}
									extraClass=''
								/>
							</p>
						</div>
						<div className='flex w-fit h-fit text-[80px] text-white font-poppinsL'>
							<p>
								<HeadingTextAnimation
									text={"no"}
									isVisible={isVisible}
									idx={"3"}
									extraClass='mr-[28px] '
								/>
							</p>
							<p>
								<HeadingTextAnimation
									text={"bite"}
									isVisible={isVisible}
									idx={"3"}
									extraClass='text-squeeze-yellow mr-[28px] '
								/>
							</p>
							<p>
								<HeadingTextAnimation
									text={"huh?"}
									isVisible={isVisible}
									idx={"3"}
									extraClass=' '
								/>
							</p>
						</div>
					</div>
					<div className='w-fit h-fit absolute top-[169px] left-[158px]'>
						<SingleEye
							folder='TopLeft'
							id='1'
							eyeClass='top-[11px] left-[150px]'
						/>
					</div>
					<div className='w-fit h-fit absolute top-[350px] left-[1060px]'>
						<SingleEye
							folder='TopRight'
							id='2'
							eyeClass='top-[11px] left-[68px]'
						/>
					</div>
					<div className='w-fit h-fit absolute top-[430px] left-[92px]'>
						<SingleEye
							folder='LeftMiddle'
							id='3'
							eyeClass='top-[3px] left-[41px]'
						/>
					</div>
					<div className='w-fit h-fit absolute top-[815px] left-[62px]'>
						<SingleEye
							folder='BottomLeft'
							id='4'
							eyeClass='top-[7px] left-[90px]'
						/>
					</div>
					<div className='w-fit h-fit absolute top-[655px] left-[1100px]'>
						<SingleEye
							folder='BottomRight'
							id='5'
							eyeClass='top-[9px] left-[110px]'
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default EyeSection;
