import { useRef, useState } from "react";
import gsap from "gsap";

const GoodBad = () => {
	const [isFlipped1, setIsFlipped1] = useState<boolean>(false);
	const [hovered1, setHovered1] = useState<boolean>(false);
	const flipping1 = useRef<boolean>(false);

	const frontRef1 = useRef<HTMLDivElement>(null);

	function flipCard1() {
		if (flipping1.current) return;
		flipping1.current = true;

		const flipDuration = 0.6;
		gsap.to(frontRef1.current, {
			rotationY: 90,
			duration: flipDuration / 2,
			ease: "power2.in",
			onComplete: () => {
				setIsFlipped1((prev) => !prev);

				gsap.to(frontRef1.current, {
					rotationY: 0,
					duration: flipDuration / 2,
					ease: "power2.out",
					onComplete: () => {
						flipping1.current = false;

						if (isFlipped1 !== hovered1) {
							flipCard1();
						}
					},
				});
			},
		});
	}

	function handleMouseEnter1() {
		setHovered1(true);
		if (!isFlipped1) {
			flipCard1();
		}
	}

	function handleMouseLeave1() {
		setHovered1(false);
		if (isFlipped1) {
			flipCard1();
		}
	}

	const [isFlipped2, setIsFlipped2] = useState<boolean>(false);
	const [hovered2, setHovered2] = useState<boolean>(false);
	const flipping2 = useRef<boolean>(false);

	const frontRef2 = useRef<HTMLDivElement>(null);

	function flipCard2() {
		if (flipping2.current) return;
		flipping2.current = true;

		const flipDuration = 0.6;
		gsap.to(frontRef2.current, {
			rotationY: 90,
			duration: flipDuration / 2,
			ease: "power2.in",
			onComplete: () => {
				setIsFlipped2((prev) => !prev);

				gsap.to(frontRef2.current, {
					rotationY: 0,
					duration: flipDuration / 2,
					ease: "power2.out",
					onComplete: () => {
						flipping2.current = false;

						if (isFlipped2 !== hovered2) {
							flipCard2();
						}
					},
				});
			},
		});
	}

	function handleMouseEnter2() {
		setHovered2(true);
		if (!isFlipped2) {
			flipCard2();
		}
	}

	function handleMouseLeave2() {
		setHovered2(false);
		if (isFlipped2) {
			flipCard2();
		}
	}

	return (
		<div className='h-[833px] relative flex flex-col items-center justify-center widthClass'>
			<div className='absolute top-[160px] left-[458px] z-10'>
				<div className='relative'>
					<div
						className={`w-[273px] h-[339px] absolute top-0 left-0 flex items-center justify-center`}
						ref={frontRef1}
						onMouseEnter={handleMouseEnter1}
						onMouseLeave={handleMouseLeave1}
					>
						<img
							src='/assets/GoodBadSection/white-rectangle.svg'
							alt='White rectangle'
							className=''
						/>
						{!isFlipped1 && (
							<img
								src='/assets/GoodBadSection/halo.svg'
								alt='Halo'
								className='absolute top-[27px] left-[37px] z-10'
							/>
						)}

						{isFlipped1 ? (
							<div className='flex items-center absolute w-[208px]'>
								<div className='text-squeeze-black text-[24px] leading-[110.5%] font-poppinsM'>
									<p className=''>We can bridge</p>
									<p className=''>the gap between</p>
									<p className=''>
										where{" "}
										<span className='text-squeeze-pink font-poppinsBl'>
											you are
										</span>
									</p>
									<p className=''>
										and where{" "}
										<span className='text-squeeze-pink font-poppinsBl'>
											you
										</span>
									</p>
									<p className='text-squeeze-pink font-poppinsBl'>
										want to be
										<span className='font-poppinsB text-squeeze-black'>.</span>
									</p>
								</div>
							</div>
						) : (
							<div className='absolute top-[118px] left-[32px] z-10'>
								<p className='leading-none'>
									<span className='font-poppinsL text-[47px] text-squeeze-pink'>
										the
									</span>
									<br />
									<span className='font-poppinsBl text-[63px] text-squeeze-black'>
										GOOD
									</span>
									<br />
									<span className='font-poppinsBl text-[63px] text-squeeze-pink'>
										NEWS
									</span>
								</p>
							</div>
						)}
					</div>
				</div>
			</div>

			<div className='absolute bsolute top-[160px] left-[780px] z-10'>
				<div className='relative'>
					<div
						className={`w-[273px] h-[339px] absolute top-0 left-0 flex items-center justify-center`}
						ref={frontRef2}
						onMouseEnter={handleMouseEnter2}
						onMouseLeave={handleMouseLeave2}
					>
						<img
							src='/assets/GoodBadSection/black-rectangle.svg'
							alt='Black rectangle'
							className=''
						/>
						{!isFlipped2 && (
							<img
								src='/assets/GoodBadSection/horns.svg'
								alt='Horns'
								className='absolute top-[50px] left-[58px] z-10'
							/>
						)}

						{isFlipped2 ? (
							<div className='flex items-center absolute w-[208px]'>
								<div className='text-white text-[26px] leading-[110.5%] font-poppinsEb uppercase'>
									<p className=''>Welcome</p>
									<p className=''>to the</p>
									<p className='text-squeeze-pink '>'No Bad</p>

									<p className='text-squeeze-pink '>
										News Zone'
										<span className='text-white'>!</span>
									</p>
								</div>
							</div>
						) : (
							<div className='absolute top-[118px] left-[32px] z-10'>
								<p className='leading-none'>
									<span className='font-poppinsL text-[47px] text-white'>
										the
									</span>
									<br />
									<span className='font-poppinsBl text-[63px] text-squeeze-pink'>
										BAD
									</span>
									<br />
									<span className='font-poppinsBl text-[63px] text-white'>
										NEWS
									</span>
								</p>
							</div>
						)}
					</div>
				</div>
			</div>

			<img
				src='/assets/GoodBadSection/yellow-stars.svg'
				alt='Yellow-stars'
				className='absolute top-[230px] left-[319px] animate-bounce'
			/>
			<img
				src='/assets/GoodBadSection/yellow-stripes.svg'
				alt='Yellow stripes'
				className='absolute top-[450px] left-[1050px] animate-pulse'
			/>
			<img
				src='/assets/GoodBadSection/shell-swirl.svg'
				alt='Shell swirl'
				className='absolute top-[356px] left-[67px] -rotate-[10deg]'
			/>
		</div>
	);
};

export default GoodBad;
