import ChatButton from "components/ChatButton";
import { usePopup } from "contexts/PopupContext";
import { Link } from "react-router-dom";

const Header = () => {
	
	return (
		<div className='container flex items-center justify-center mx-auto h-[65px] z-40'>
			<div className='relative flex justify-between items-center w-[1512px]'>
				<img src='/assets/logo.svg' alt='Squeeze creative agency logo ' />
				<div
					className={`flex w-[326px] justify-between text-[#7B8289] text-[14px] font-roboto `}
				>
					<div className={` flex items-center justify-center`}>
						<Link to='/'>home</Link>
					</div>
					<div className={` flex items-center justify-center`}>
						<Link to='/'>services</Link>
					</div>
					<div className={` flex items-center justify-center`}>
						<Link to='/'>blog</Link>
					</div>
					<div className={` flex items-center justify-center`}>
						<ChatButton />
					</div>
				</div>
			</div>
		</div>
	);
};

export default Header;
