import React, { useRef, useEffect, useState, useLayoutEffect } from "react";
import { gsap } from "gsap";

// Update the image source and ensure we have enough images
const marqueeImages = [
	"/assets/YouSection/you.svg",
	"/assets/YouSection/you.svg",
	"/assets/YouSection/you.svg",
	"/assets/YouSection/you.svg",
	"/assets/YouSection/you.svg",
	"/assets/YouSection/you.svg",
];

interface MarqueeProps {
	trigger: string; // Flag to trigger the marquee animation setup
}

const Marquee: React.FC<MarqueeProps> = ({ trigger }) => {
	const marqueeElements = useRef<HTMLDivElement[]>([]);
	const [screenWidth, setScreenWidth] = useState(window.innerWidth);
	const marqueeTween = useRef<gsap.core.Tween | null>(null);

	useLayoutEffect(() => {
		const resizeHandler = () => {
			setScreenWidth(window.innerWidth);
			gsap.set(marqueeElements.current, { clearProps: "all" });
			setupMarquee();
		};

		const setupMarquee = () => {
			if (marqueeElements.current.length === 0) return;

			const elementWidth = marqueeElements.current[0]?.offsetWidth || 0;
			const numVisible = Math.ceil(screenWidth / elementWidth) + 1; // Number of images to ensure at least 3 visible

			// Ensure the marquee spans the width of the viewport
			const distance = elementWidth * numVisible;

			// Set initial positions
			gsap.set(marqueeElements.current, {
				x: (index) => elementWidth * index - 790,
			});

			// Kill previous tween if exists
			if (marqueeTween.current) {
				marqueeTween.current.pause().kill();
			}

			// Create a new tween
			marqueeTween.current = gsap.to(marqueeElements.current, {
				x: `${trigger}=${distance}`,
				ease: "none",
				repeat: -1,
				duration: 20,
				modifiers: {
					x: (x) => {
						return `${((parseFloat(x) % distance) + distance) % distance}px`;
					},
				},
			});
		};

		// Initial setup and watch for changes in trigger
		if (trigger) {
			setupMarquee();
		}
		window.addEventListener("resize", resizeHandler);
		return () => {
			window.removeEventListener("resize", resizeHandler);
			if (marqueeTween.current) {
				marqueeTween.current.pause().kill();
			}
		};
	}, [screenWidth, trigger]); // Added trigger to dependencies

	const marqueeElementsRefHandler = (e: HTMLDivElement, i: number) => {
		marqueeElements.current[i] = e;
	};

	const renderMarqueeElements = () => {
		return marqueeImages.map((src, i) => (
			<div
				className='absolute flex items-center justify-center w-[790px]'
				key={`marquee-${i}`}
				ref={(el) => marqueeElementsRefHandler(el as HTMLDivElement, i)}
			>
				<img
					src={src}
					alt={`Rolling you ${i}`}
					className='h-[248px]'
				/>
			</div>
		));
	};

	return (
		<div
			className='relative flex items-center overflow-clip'
			style={{ minHeight: "248px", width: "200vw" }}
		>
			{renderMarqueeElements()}
		</div>
	);
};

export default Marquee;
