import ContactPopup from "components/Contact/ContactPopup";
import { PopupProvider } from "contexts/PopupContext";
import Home from "pages/Home/Home";
import { BrowserRouter } from "react-router-dom";
const App = () => {
	document.getElementById("root");
	return (
		<div>
			<PopupProvider>
				<ContactPopup />
				<BrowserRouter>
					<Home />
				</BrowserRouter>
			</PopupProvider>
		</div>
	);
};

export default App;
