import Marquee from "components/Marquee";
import TextAnimation from "../../BodyTextAnimation";
import { usePopup } from "contexts/PopupContext";

const YouSection = ({ isVisible }: { isVisible: boolean }) => {
	const { setPopupOpen } = usePopup();
	return (
		<div className='h-[1065px] relative flex flex-col items-center justify-center widthClass overflow-clip'>
			<div className='w-[1512px] h-[1065px] flex flex-col items-center justify-center '>
				<img
					src='/assets/YouSection/star.svg'
					alt='Yellow star'
					className='absolute top-[435px] left-[290px]'
				/>
				<img
					src='/assets/YouSection/white-you.svg'
					alt="White 'you'"
					className=' absolute top-[520px] left-[350px] z-20'
				/>

				<img
					src='/assets/YouSection/yellow-you.svg'
					alt="Yellow 'you'"
					className=' absolute top-[576px] left-[338px] z-10 '
				/>
				<p className='absolute top-[362px] left-[359px] text-white font-poppinsB text-[80px] -rotate-[7deg] uppercase'>
					we’re all{" "}
				</p>
				<p className='absolute top-[445px] left-[440px] text-white font-poppinsB text-[80px] -rotate-[7deg] uppercase'>
					about
				</p>
				<div className='absolute top-[454px] left-[782px] font-robotol text-[20px] leading-[114.5%] text-white space-y-[17px]'>
					<p>
						<TextAnimation
							idx={"1"}
							isVisible={isVisible}
							text={`Picture this: each strategy we craft is like a bespoke `}
						/>

						<TextAnimation
							idx={"1"}
							isVisible={isVisible}
							text='suit tailored precisely to your unique needs, wants,'
						/>

						<TextAnimation
							idx={"1"}
							isVisible={isVisible}
							text='and requirements.'
						/>
					</p>
					<p>
						<TextAnimation
							idx={"2"}
							isVisible={isVisible}
							text='We dive deep into your world, understanding your'
						/>
						<TextAnimation
							idx={"2"}
							isVisible={isVisible}
							text='user persona and ensuring that every move we make'
						/>
						<TextAnimation
							idx={"2"}
							isVisible={isVisible}
							text='is a step towards resonating with your audience, all'
						/>
						<TextAnimation
							idx={"2"}
							isVisible={isVisible}
							text='while guiding them through your funnel.'
						/>
					</p>
					<p>
						<TextAnimation
							idx={"3"}
							isVisible={isVisible}
							text="And just so you know - our decisions aren't left"
						/>
						<TextAnimation
							idx={"3"}
							isVisible={isVisible}
							text='to guesswork: data is our guiding star, leading us to the'
						/>
						<TextAnimation
							idx={"3"}
							isVisible={isVisible}
							text='	most informed choices.'
						/>
					</p>
				</div>
				<button
					onClick={()=>{setPopupOpen(true)}}
					className={`absolute left-[781px] top-[748px] uppercase flex items-center justify-center rounded-full bg-squeeze-white border-2 border-squeeze-yellow w-[175px] hover:bg-squeeze-yellow h-[56px] text-[21px] font-poppinsSb hover:text-squeeze-black text-squeeze-yellow `}
				>
					let's chat
				</button>
				
				<div className='absolute -rotate-[7deg] -top-[80px] '>
					<Marquee trigger={"+"} />
				</div>
				<div className='absolute -rotate-[7deg] -bottom-[90px]'>
					<Marquee trigger={"-"} />
				</div>
			</div>
		</div>
	);
};

export default YouSection;
