import { usePopup } from "contexts/PopupContext";

const ChatButton = ({ eclass }: { eclass?: string }) => {
	const {  setPopupOpen } = usePopup();

	const togglePopup = () => {
		setPopupOpen((prev) => !prev);
	};

	return (
		<div
			className={` flex items-center justify-center cursor-pointer`}
			onClick={togglePopup}
		>
			<div
				className={`uppercase flex items-center justify-center rounded-full bg-squeeze-pink w-[114px] h-[35px] font-robotom text-white `}
			>
				let's chat
			</div>
		</div>
	);
};

export default ChatButton;
