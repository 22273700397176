import React, { useEffect, useRef, useState } from "react";
import { gsap } from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
import EyeSection from "components/Home/EyeSection/EyeSection";
import styles from "./home.module.css";
import Header from "components/Header/Header";
import UnleashSection from "components/Home/UnleashSection/UnleashSection";
import Cards from "components/Home/PinkSections/Cards";
import GoodBad from "components/Home/PinkSections/GoodBadSection/GoodBad";
import GetThere from "components/Home/GetThereSection/GetThere";
import YouSection from "components/Home/YouSection/YouSection";
import { useGSAP } from "@gsap/react";
import { Link } from "react-router-dom";
import HeadingTextAnimation from "components/HeadingTextAnimation";
import ClosingSection from "components/Home/ClosingSection/ClosingSection";
import Footer from "components/Footer/Footer";

gsap.registerPlugin(ScrollTrigger);

const Home: React.FC = () => {
	useGSAP(() => {
		const sections = document.querySelectorAll<HTMLElement>("[data-bgcolor]");
		if (visibleSection === null) {
			setVisibleSection("unleash");
			setPrevVisibleSection("unleash");
		}
		sections.forEach((section, i) => {
			const prevBgColor =
				i === 0 ? "#ffffff" : sections[i - 1].dataset.bgcolor || "";

			const tl = gsap.timeline({
				scrollTrigger: {
					trigger: section,
					scroller: "#scrollDemo",
					start: "top 50%",
					end: "bottom 50%",
					onEnter: () => {
						if (prevVisibleSection !== section.id) {
							setVisibleSection(section.id || null);
							setPrevVisibleSection(section.id);
						}
						gsap.to("#scrollDemo", {
							backgroundColor: section.dataset.bgcolor,
							color: section.dataset.textcolor,
							overwrite: "auto",
						});
						gsap.to(section, {
							opacity: 1,
							visibility: "visible",
							duration: 1.5,
						});
					},
					onLeave: () => {
						if (section.id === "closingSection") {
						} else {
							gsap.to(section, {
								opacity: 0,
								visibility: "hidden",
								duration: 1.5,
							});
						}
					},
					onEnterBack: () => {
						if (prevVisibleSection !== section.id) {
							setVisibleSection(section.id || null);
							setPrevVisibleSection(section.id);
						}
						gsap.to("#scrollDemo", {
							backgroundColor: section.dataset.bgcolor,
							color: section.dataset.textcolor,
							overwrite: "auto",
						});
						gsap.to(section, {
							opacity: 1,
							visibility: "visible",
							duration: 1.5,
						});
					},
					onLeaveBack: () => {
						gsap.to("#scrollDemo", {
							backgroundColor: prevBgColor,
							overwrite: "auto",
						});
						gsap.to(section, {
							opacity: 0,
							visibility: "hidden",
							duration: 1.5,
						});
					},
				},
			});
		});
	}, []);

	const scrollDemoRef = useRef(null);
	const [scrollTimeout, setScrollTimeout] = useState<NodeJS.Timeout | null>(
		null
	);
	const [lastScroll, setLastScroll] = useState<number>(0);

	const handleScroll = () => {
		const head = document.getElementById("header");

		if (scrollDemoRef.current) {
			const { scrollTop } = scrollDemoRef.current;

			if (scrollTimeout) {
				clearTimeout(scrollTimeout);
			}

			const newTimeout = setTimeout(() => {
				if (scrollTop <= 0) {
					head?.classList.remove("scroll-up");
				}
				if (scrollTop > lastScroll) {
					head?.classList.remove("scroll-up");
					head?.classList.add("scroll-down");
				}
				if (scrollTop < lastScroll) {
					head?.classList.remove("scroll-down");
					head?.classList.add("scroll-up");
				}

				setLastScroll(scrollTop);
			}, 100);

			setScrollTimeout(newTimeout);
		}
	};

	const [prevVisibleSection, setPrevVisibleSection] = useState<string | null>(
		null
	);
	const [visibleSection, setVisibleSection] = useState<string | null>(null);

	useGSAP(() => {
		const timelinecapBR = gsap.timeline({
			scrollTrigger: {
				trigger: "#cont",
				scroller: "#scrollDemo",
				start: "top center",
				end: "center center",

				scrub: 0.5,
			},
		});
		timelinecapBR.fromTo(
			"#capsuleBR",
			{ x: 1500, y: 0 },
			{ position: "relative", x: 980, y: 340 }
		);

		const timelinecapTR = gsap.timeline({
			scrollTrigger: {
				trigger: "#cont",
				scroller: "#scrollDemo",
				start: "top center",
				end: "center center",
				scrub: 0.5,
			},
		});
		timelinecapTR.fromTo("#capsuleTR", { x: 1401, y: 80 }, { x: 930, y: 350 });

		const timelineCircTL = gsap.timeline({
			scrollTrigger: {
				trigger: "#cont",
				scroller: "#scrollDemo",
				start: "top center",
				end: "center center",

				scrub: 0.5,
			},
		});
		timelineCircTL.fromTo("#circleTL", { x: -100, y: 86 }, { x: 250, y: 150 });

		const timelineRectL = gsap.timeline({
			scrollTrigger: {
				trigger: "#cont",
				scroller: "#scrollDemo",
				start: "top center",
				end: "center center",

				scrub: 0.5,
			},
		});
		timelineRectL.fromTo("#rectL", { x: -100, y: 1000 }, { x: 220, y: 520 });

		const timelineCircleBL = gsap.timeline({
			scrollTrigger: {
				trigger: "#cont",
				scroller: "#scrollDemo",
				start: "top center",
				end: "center center",
				scrub: 0.5,
			},
		});

		timelineCircleBL.fromTo(
			"#circleBL",
			{ x: -50, y: 700 },
			{ x: 270, y: 675 }
		);

		timelineCircleBL.fromTo(
			"#circleBL",
			{ x: 270, y: 575 },
			{ position: "relative", x: 500, y: 500 }
		);
	}, []);

	useGSAP(() => {
		const timelineWaves = gsap.timeline({
			scrollTrigger: {
				trigger: "#waves",
				scroller: "#scrollDemo",
				start: "top top",
				scrub: 2,
			},
		});
		timelineWaves.fromTo("#waves", { x: -440, y: 290 }, { x: -440, y: 1250 });

		const timelineStars = gsap.timeline({
			scrollTrigger: {
				trigger: "#waves",
				scroller: "#scrollDemo",
				start: "top top",
				scrub: 5,
			},
		});
		timelineStars.fromTo("#pinkStars", { x: 480, y: 725 }, { x: 480, y: 1150 });

		const timelineBirds = gsap.timeline({
			scrollTrigger: {
				trigger: "#waves",
				scroller: "#scrollDemo",
				start: "top top",
				scrub: 5,
			},
		});
		timelineBirds.fromTo(
			"#yellowBirds",
			{ x: -500, y: 290 },
			{ x: -500, y: 1250 }
		);
	}, []);

	const [trustFlag, setTrustFlag] = useState<boolean>(false);

	useGSAP(() => {
		const trustTl = gsap.timeline({
			scrollTrigger: {
				trigger: "#trustSection",
				scroller: "#scrollDemo",
				start: "top center",
				end: "bottom center",
				onEnter: () => {
					setTrustFlag(true);
				},
				onEnterBack: () => {
					setTrustFlag(true);
				},
				onLeave: () => {
					setTrustFlag(false);
				},
				onLeaveBack: () => {
					setTrustFlag(false);
				},
			},
		});
	}, []);

	useGSAP(() => {
		const timelineCsRectTR = gsap.timeline({
			scrollTrigger: {
				trigger: "#closingCont",
				scroller: "#scrollDemo",
				start: "top center",
				end: "center center",

				scrub: 2,
			},
		});
		timelineCsRectTR.fromTo(
			"#csRectTR",
			{ x: 1130, y: 50, scale: 1.5 },
			{ x: 1130, y: 120, scale: 1 }
		);
		const timelineCsRectMr = gsap.timeline({
			scrollTrigger: {
				trigger: "#closingCont",
				scroller: "#scrollDemo",
				start: "top center",
				end: "center center",

				scrub: 3,
			},
		});
		timelineCsRectMr.fromTo(
			"#csRectMR",
			{ x: 995, y: 500, scale: 0.5 },
			{ x: 995, y: 400, scale: 1 }
		);
		const timelineCsRectBr = gsap.timeline({
			scrollTrigger: {
				trigger: "#closingCont",
				scroller: "#scrollDemo",
				start: "top center",
				end: "center center",

				scrub: 3,
			},
		});
		timelineCsRectBr.fromTo(
			"#csRectBR",
			{ x: 1330, y: 200, rotate: 20 },
			{ x: 1330, y: 80, rotate: 0 }
		);
		const timelineCsRectTL = gsap.timeline({
			scrollTrigger: {
				trigger: "#closingCont",
				scroller: "#scrollDemo",
				start: "top center",
				end: "center center",

				scrub: 6,
			},
		});
		timelineCsRectTL.fromTo(
			"#csRectL",
			{ x: -150, y: 430, rotate: 20 },
			{ x: -150, y: 50, rotate: 0 }
		);
	}, []);

	return (
		<div
			id='scrollDemo'
			ref={scrollDemoRef}
			onScroll={handleScroll}
			className='relative flex flex-col justify-start w-full h-screen overflow-x-hidden overflow-y-scroll main'
		>
			<div
				id='header'
				className='sticky top-0 z-40 w-full py-2 flex items-center bg-white h-[113px]'
			>
				<Header />
			</div>
			<section
				id='unleash'
				className={` ${styles.section}`}
				data-bgcolor='#ffffff'
			>
				<UnleashSection isVisible={visibleSection === "unleash"} />
			</section>
			<section
				id='cardsTrustGoodBad'
				className={`h-[2499px] container relative flex flex-col items-center w-widthClass mx-auto opacity-0 `}
				data-bgcolor='#EE2A7B'
			>
				<img
					src='/assets/QCSection/yellow-birds.svg'
					id='yellowBirds'
					alt='yellow birds'
					className='z-30 abasolute'
				/>
				<img
					src='/assets/QCSection/pink-stars.svg'
					id='pinkStars'
					alt='Pink stars'
					className='absolute'
				/>
				<img
					src='/assets/QCSection/waves2.svg'
					alt='Waves'
					id='waves'
					className='absolute  w-[1115px] h-[421px] '
				/>
				<div className=' h-[1666px] relative flex flex-col items-center justify-center widthClass overflow-x-clip'>
					<Cards />
					<div
						className='w-[1512px] h-[833px] flex flex-col items-center justify-center text-white whitespace-nowrap'
						id='trustSection'
					>
						<HeadingTextAnimation
							idx={"1"}
							isVisible={trustFlag}
							text='Trust us'
							extraClass='text-[96px] font-poppinsBl text-white'
						/>
						<HeadingTextAnimation
							idx={"3"}
							isVisible={trustFlag}
							text='We understand your problem'
							extraClass='text-[48px] font-poppins text-white'
						/>
					</div>
				</div>
				<GoodBad />
			</section>

			<section
				id='getThere'
				className={`h-[1080px] ${styles.section}`}
				data-bgcolor='#FFDD00'
			>
				<GetThere isVisible={visibleSection === "getThere"} />
			</section>
			<section
				id='youSection'
				className={`h-[1065px] ${styles.section}`}
				data-bgcolor='#141A21'
			>
				<YouSection isVisible={visibleSection === "youSection"} />
			</section>
			<section
				className={`h-[1490px] ${styles.section}`}
				data-bgcolor='#FFDD00'
			>
				<div className='h-[1490px] relative flex flex-col items-center justify-center widthClass overflow-x-visible'>
					<div className='w-[1512px] h-[1490px] flex flex-col items-center justify-center '>
						<div className='widthClass h-[1490px] realtive' id='cont'>
							<img
								src='/assets/ServicesSection/capsule-top-right.svg'
								alt='Top right pink capsule'
								id='capsuleTR'
							/>
							<img
								src='/assets/ServicesSection/circle-top-left.svg'
								alt='Top left pink circle'
								id='circleTL'
							/>
							<img
								src='/assets/ServicesSection/rectangle-left.svg'
								alt='Left side pink rectangle'
								id='rectL'
							/>
							<img
								src='/assets/ServicesSection/circle-bottom.svg'
								alt='Bottom left pink circle'
								id='circleBL'
								className='z-10'
							/>

							<img
								src='/assets/ServicesSection/capsule-bottom-right.svg'
								alt='Bottom right pink capsule'
								id='capsuleBR'
								className='z-20'
							/>
							<Link
								to='/services'
								className='absolute top-[330px] left-[414px] cursor-pointer'
							>
								<img
									src='/assets/ServicesSection/1.svg
      '
									alt='Graphic design'
									className='hover:scale-105'
								/>
							</Link>

							<Link
								to='/services'
								className='absolute top-[714px] left-[790px] z-0 hover:z-30 cursor-pointer'
							>
								<img
									src='/assets/ServicesSection/2.svg
      '
									alt='Social Media Marketing'
									className='hover:scale-105 '
								/>
							</Link>
							<Link
								to='/services'
								className='absolute top-[675px] left-[270px] z-20 hover:z-30 cursor-pointer'
							>
								<img
									src='/assets/ServicesSection/3.svg
      '
									alt='Copywriting and dontent writing'
									className='hover:scale-105'
								/>
							</Link>
							<Link
								to='/services'
								className='absolute top-[424px] left-[786px] cursor-pointer'
							>
								<img
									src='/assets/ServicesSection/4.svg
      '
									alt='SEO'
									className='hover:scale-105'
								/>
							</Link>
						</div>
					</div>
				</div>
			</section>
			<section
				id='eyeSection'
				className={`
					h-[1172px] ${styles.section}`}
				data-bgcolor='#141A21'
			>
				<EyeSection isVisible={visibleSection === "eyeSection"} />
			</section>
			<section
				id='closingSection'
				className={`h-[1400px] ${styles.section}`}
				data-bgcolor='#EE2A7B'
			>
				<div className='h-[1400px] relative flex flex-col items-center justify-center widthClass overflow-x-visible'>
					<div className='w-[1512px] h-[1400px] flex flex-col items-center justify-center '>
						<div className='widthClass h-[1400px] realtive' id='closingCont'>
							<img
								src='/assets/ClosingSection/rect-mid-right.svg'
								alt='Midlle yellow rectangle'
								id='csRectMR'
							/>
							<img
								src='/assets/ClosingSection/rect-top-right.svg'
								alt='Top right yellow rectangle'
								id='csRectTR'
							/>
							<img
								src='/assets/ClosingSection/rect-left.svg'
								alt='Left yellow rectangle'
								id='csRectL'
							/>
							<img
								src='/assets/ClosingSection/rect-bot-right.svg'
								alt='Bottom right yellow rectangle'
								id='csRectBR'
							/>
							<ClosingSection isVisible={visibleSection === "closingSection"} />
						</div>
					</div>
				</div>
				<div className='absolute bottom-[180px] left-[900px] w-fit h-fit z-[100]'>
					<div className='text-[19px] relative w-[260px] h-[157px] hover:scale-110'>
						<a href='https://amalasolutions.com/' target="_blank" rel="noopener noreferrer">
							<div className='absolute w-fit h-fit z-20 top-[60px] text-squeeze-black left-[45px]'>
								<p className='font-poppinsM'>Like our website?</p>
								<p className='font-poppinsB'>AMALA made it!</p>
							</div>
							<img
								src='/assets/Footer/amala-rect.svg'
								alt='Amala yellow rect'
								className='absolute bottom-0 left-0 z-10'
							/>
							<img
								src='/assets/Footer/arrow.svg'
								alt='Arrow'
								className='absolute top-0 right-0 z-20'
							/>
						</a>
					</div>
				</div>
				<Footer />
			</section>
		</div>
	);
};

export default Home;
