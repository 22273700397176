import { usePopup } from "contexts/PopupContext";
import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
	const { setPopupOpen } = usePopup();
	return (
		<div className='widthClass overflow-x-clip h-[320px] bg-transparent z-40 absolute bottom-0'>
			<div className='absolute flex flex-col items-center justify-center bottom-[50px] widthClass h-fit font-poppinsL'>
				<div className='w-[1140px] h-[77px] justify-between items-center flex'>
					<img src='assets/logo.svg' alt='Squeeze creative logo' />
					<div className='w-[360px] h-fit  text-[15px] flex items-center justify-between text-squeeze-black'>
						<Link to='/'>home</Link>
						<img src='/assets/Footer/border.svg' alt='border 1' />
						<Link to='/services'>services</Link>
						<img src='/assets/Footer/border.svg' alt='border 2' />
						<Link to='/blog'>blog</Link>
						<img src='/assets/Footer/border.svg' alt='border 3' />
						<button onClick={()=>{setPopupOpen(true)}}>let's chat</button>
					</div>
					<div className='w-[130px] h-fit flex items-center justify-between'>
						<a href='https://www.facebook.com/profile.php?id=100095734291715&mibextid=ZbWKwL'>
							<img src='/assets/Footer/fb.svg' alt='Facebook icon' />
						</a>
						<a href='https://www.instagram.com/squeezecreativeagency/?hl=en'>
							<img src='/assets/Footer/insta.svg' alt='Instagram icon' />
						</a>
						<a href='https://www.linkedin.com/company/squeeze-creative-agency/?trk=public_post_feed-actor-name'>
							<img src='/assets/Footer/li.svg' alt='Linked In icon' />
						</a>
					</div>
				</div>
                <p className="text-[11px]">Copyright @2023</p>
			</div>
			<img
				src='/assets/footer/bg.svg'
				alt='White background'
				className='aboslute bottom-0 left-[50%]'
			/>
		</div>
	);
};

export default Footer;
