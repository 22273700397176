import TextAnimation from "components/BodyTextAnimation";
import Footer from "components/Footer/Footer";
import HeadingTextAnimation from "components/HeadingTextAnimation";
import React from "react";

const ClosingSection = ({ isVisible }: { isVisible: boolean }) => {
	return (
		<div className='h-[565px] w-[833px] flex flex-col items-center justify-center  overflow-x-visible absolute top-[333px] left-[372px]'>
			<div className='w-[833px] h-[565px] flex flex-col items-center justify-center  '>
				<img
					src='/assets/ClosingSection/text-rect.svg'
					alt='Border around text'
					className={` absolute top-[0px] left-[0px]`}
				/>
				<div className='font-poppinsB text-[33px] absolute top-[40px] left-[53px] -space-y-[35px] text-white '>
					<div
						className='flex space-x-[9px] items-center justify-start w-fit h-fit '
						style={{ transform: "translateY(12px)" }}
					>
						<p className=' h-fit'>
							<HeadingTextAnimation
								text={"With"}
								isVisible={isVisible}
								idx={"1"}
							/>
						</p>
						<p className='uppercase text-squeeze-yellow font-poppinsBl text-[40px] '>
							<HeadingTextAnimation
								text={"10+ years"}
								isVisible={isVisible}
								idx={"1"}
								extraClass='mb-[18px] '
							/>
						</p>
					</div>
					<p className=' h-fit'>
						<HeadingTextAnimation
							text={"of experience in the"}
							isVisible={isVisible}
							idx={"1"}
						/>
					</p>
					<p className=' h-fit'>
						<HeadingTextAnimation
							text={"industry, we came"}
							isVisible={isVisible}
							idx={"1"}
						/>
					</p>
					<p className=' h-fit'>
						<HeadingTextAnimation
							text={"together to build"}
							isVisible={isVisible}
							idx={"1"}
						/>
					</p>
					<p className='uppercase text-squeeze-yellow font-poppinsBl text-[40px] h-fit  '>
						<HeadingTextAnimation
							text={"Squeeze Creative."}
							isVisible={isVisible}
							idx={"3"}
							extraClass=' '
						/>
					</p>
				</div>
				<div className='absolute top-[300px] left-[380px] font-roboto text-[20px] text-white leading-[114.5%] space-y-[17px]'>
					<p className=''>
						<TextAnimation
							text={"Right from the start, we knew what our vision"}
							isVisible={isVisible}
							idx={"1"}
						/>
						<TextAnimation
							text={"was: Inspire and encourage creative minds to"}
							isVisible={isVisible}
							idx={"1"}
						/>
						<TextAnimation
							text={"break the status quo, fostering a world where"}
							isVisible={isVisible}
							idx={"1"}
						/>
						<TextAnimation
							text={"boundless imagination and transformative"}
							isVisible={isVisible}
							idx={"1"}
						/>
						<TextAnimation
							text={"ideas thrive."}
							isVisible={isVisible}
							idx={"1"}
						/>
					</p>
					<p>
						<TextAnimation
							text={"It was clear as day we had our mission set out"}
							isVisible={isVisible}
							idx={"2"}
						/>
						<TextAnimation
							text={"for ourselves: Empower businesses with data-"}
							isVisible={isVisible}
							idx={"2"}
						/>
						<TextAnimation
							text={"driven marketing strategies and a user-centric"}
							isVisible={isVisible}
							idx={"2"}
						/>
						<TextAnimation
							text={"approach tailored to their specific needs."}
							isVisible={isVisible}
							idx={"2"}
						/>
					</p>
				</div>
			</div>
		
		</div>
	);
};

export default ClosingSection;
