import React, { createContext, useState, useContext, ReactNode } from 'react';


interface PopupContextType {
    popupOpen: boolean;
    setPopupOpen: React.Dispatch<React.SetStateAction<boolean>>;
}


const PopupContext = createContext<PopupContextType | undefined>(undefined);


export const PopupProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [popupOpen, setPopupOpen] = useState<boolean>(false);

    return (
        <PopupContext.Provider value={{ popupOpen, setPopupOpen }}>
            {children}
        </PopupContext.Provider>
    );
};

export const usePopup = (): PopupContextType => {
    const context = useContext(PopupContext);
    if (context === undefined) {
        throw new Error('usePopup must be used within a PopupProvider');
    }
    return context;
};
