import { usePopup } from "contexts/PopupContext";
import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { toastDefaultOptions } from "../../lib/util";
import "react-toastify/dist/ReactToastify.css";
import { contactService } from "services/contact";

const ContactPopup = () => {
	const { popupOpen, setPopupOpen } = usePopup();

	const [name, setName] = useState<string>("");
	const [email, setEmail] = useState<string>("");
	const [message, setMessage] = useState<string>("");
	const [isSendingMessage, setIsSendingMessage] = useState<boolean>(false);

	const handleClick = () => {
		setPopupOpen(false);
	};
	const handleInnerClick = (e: React.MouseEvent<HTMLDivElement>) => {
		e.stopPropagation();
	};

	const validateEmail = (email: string) => {
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return emailRegex.test(email);
	};

	const handleSubmit = () => {
		if (!name || !email || !message) {
			toast.error("Please fill all the information.", toastDefaultOptions);
			return;
		}

		if (!validateEmail(email)) {
			toast.error("Please enter a valid email address.", toastDefaultOptions);
			return;
		}

		setIsSendingMessage(true);

		contactService
			.sendMessage(name, email, message)
			.then((response) => {
				if (response.isFailed) {
					toast.error(
						"An error occured while sending the message",
						toastDefaultOptions
					);
				} else {
					toast.success("Message sent!", toastDefaultOptions);
				}
			})
			.catch(() => {
				toast.error(
					"An error has occured while sending the message.",
					toastDefaultOptions
				);
			})
			.finally(() => {
				setIsSendingMessage(false);
			});
	};
	return (
		<div
			className={`${
				popupOpen ? "" : "hidden"
			} absolute z-50 flex items-center justify-center w-screen h-screen bg-transparent`}
			onClick={handleClick}
		>
			<div className='w-[410px] h-[550px] relative ' onClick={handleInnerClick}>
				<div className='w-[393px] h-[539px] absolute bottom-0 left-0 bg-white overflow-clip shadow-sm shadow-black rounded-[24.17px] flex items-center justify-center'>
					<div className='w-[308px] h-[433px] flex flex-col items-center justify-between z-[90]'>
						<div className='flex flex-col items-center text-center leading-[200%]'>
							<p className='font-poppinsL text-[21.65px]'>
								Let’s do great things
							</p>
							<p className='uppercase font-poppinsEb text-squeeze-pink text-[29.93px]'>
								together!
							</p>
						</div>
						<form className='flex flex-col w-full h-fit text-squeeze-black space-y-[21px]'>
							<input
								required
								onChange={({ target: { value } }) => setName(value)}
								value={name}
								type='text'
								className='w-full border border-[#7B8289] rounded-[8.86px] h-[35px] placeholder-squeeze-black px-4 py-2'
								placeholder='Name'
							/>
							<input
								required
								onChange={({ target: { value } }) => setEmail(value)}
								value={email}
								type='email'
								className='w-full border border-[#7B8289] rounded-[8.86px] h-[35px] placeholder-squeeze-black px-4 py-2'
								placeholder='E-mail adress'
							/>
							<input
								required
								onChange={({ target: { value } }) => setMessage(value)}
								value={message}
								type='text'
								className='w-full border border-[#7B8289] rounded-[8.86px] h-[115px] placeholder-squeeze-black px-4 py-2'
								placeholder='Message'
							/>
						</form>
						<button
							onClick={handleSubmit}
							type='submit'
							disabled={isSendingMessage}
							className={`uppercase flex items-center justify-center rounded-full bg-squeeze-white border-2 hover:bg-squeeze-pink hover:text-white border-squeeze-pink w-[141px] h-[45px] text-[17px] font-poppinsSb text-squeeze-pink `}
						>
							Submit
						</button>
					</div>
				</div>
				<div
					onClick={handleClick}
					className='w-[45px] h-[45px] top-0 right-0 z-[100] absolute hover:scale-110 cursor-pointer '
				>
					<img src='/assets/close.svg' alt='Close button' />
				</div>
				<img
					src='/assets/ContactPopup/side-symbols.svg'
					alt='Orangle logo'
					className='absolute bottom-0 left-0 z-[70]'
				/>
			</div>
			<ToastContainer />
		</div>
	);
};

export default ContactPopup;
