

export interface IContact {
	name: string;
	email: string;
	message: string;
}

// interface GoogleResponse {
// 	success: boolean;
// 	challenge_ts: string;
// 	hostname: string;
//   }

const contactApiUrl = `${process.env.API_URL}/contact`;

interface IContactService {
	sendMessage: (
		name: string,
		email: string,
		message: string,
		// captchaToken:string
	) => Promise<any>;
}

export const contactService: IContactService = {
	async sendMessage( name, email, message) {
		try {
			const url = new URL(contactApiUrl);
			// url.searchParams.append('captchaToken', captchaToken);

			const response = await fetch(url, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					name,
					email,
					message,
				}),
			});
			const result: any = await response.json();
			return Promise.resolve(result);
		} catch (error) {
			return Promise.reject(error);
		}
	},
};
