import HeadingTextAnimation from "components/HeadingTextAnimation";
import { useRef } from "react";

const Cards = () => {
	return (
		<div className='text-center relative w-[1512px] h-[833px]'>
			<div className='flex items-center justify-center absolute hover:scale-110 top-[143px] left-[464px]'>
				<img
					src='/assets/QCSection/black-rectangle.svg'
					alt='black rectangle'
				/>
				<p className='absolute text-[36px] font-poppinsM -rotate-2 text-white leading-[106%]'>
					Struggling
					<br />
					with online
					<br />
					obscurity?
				</p>
			</div>
			<div className='flex items-center justify-center absolute hover:scale-110 top-[254px] left-[799px]'>
				<img
					src='/assets/QCSection/white-rectangle.svg'
					alt='white rectangle'
				/>
				<p className='absolute text-[40px] font-poppinsB rotate-[8deg] leading-[111.5%]'>
					Engagement
					<br />
					eluding you on
					<br />
					social media?
				</p>
			</div>
			<div className='flex items-center justify-center absolute hover:scale-110 top-[444px] left-[398px]'>
				<img
					src='/assets/QCSection/yellow-rectangle.svg'
					alt='yellow rectangle'
				/>
				<p className='absolute text-[40px] font-poppinsI rotate-[7.51deg] leading-[111.5%]'>
					Wrestling
					<br />
					with brand
					<br />
					inconsistency?
				</p>
			</div>
			<div className='flex items-center justify-center absolute hover:scale-110 top-[535px] left-[813px]'>
				<img src='/assets/QCSection/pink-rectangle.svg' alt='pink rectangle' />
				<p className='absolute text-[36px] font-poppinsL -rotate-2 uppercase leading-[106%] text-white'>
					Content
					<br />
					feeling
					<br />
					lacklustre?
				</p>
			</div>
		</div>
	);
};

export default Cards;
