import HeadingTextAnimation from "components/HeadingTextAnimation";
import { reviews } from "lib/util";

import { Link } from "react-router-dom";

const UnleashSection = ({ isVisible }: { isVisible: boolean }) => {
	return (
		<div className='relative flex flex-col items-center justify-center widthClass h-fit'>
			<div className='w-full h-[113px]'></div>

			<div className='relative h-[681px] w-full flex justify-between items-start'>
				<div className={`space-y-[35px] text-[#141A21]`}>
					<p className=' font-poppinsEb text-[82px] leading-[104%] whitespace-pre-line capitalize'>
						<HeadingTextAnimation
							idx={"1"}
							isVisible={isVisible}
							text='Unleash'
						/>

						<HeadingTextAnimation
							idx={"2"}
							isVisible={isVisible}
							text='Your Business’'
						/>

						<HeadingTextAnimation
							idx={"3"}
							isVisible={isVisible}
							text='Full Potential'
						/>
					</p>
					<p className={`font-robotol leading-[129%] text-[24px]`}>
						Discover user-centric & data-driven marketing strategies
						<br />
						that will bring you unprecedented results.
					</p>
					<Link
						to='/'
						className={`uppercase flex items-center justify-center rounded-full bg-squeeze-white border-2 hover:bg-squeeze-pink hover:text-white border-squeeze-pink w-[175px] h-[56px] text-[24px] font-poppinsSb text-squeeze-pink `}
					>
						let's go
					</Link>
					<div className='pt-[20px]'>
						<div className='wrapper '>
							{reviews.map((review, idx) => (
								<div
									className={`flex flex-col justify-between item item${
										idx + 1
									}`}
									key={review.name + idx}
								>
									<div className='h-[26px] w-[230px] flex justify-between items-center'>
										<p className='font-poppinsSb text-[20px] leading-[129%] whitespace-nowrap'>
											{review.name}
										</p>
										<div className='flex items-center space-x-[3px]'>
											{Array.from({ length: review.rating }, (_, starIdx) => (
												<img
													key={starIdx}
													src='/assets/UnleashSection/Star.svg'
													alt='Rating star'
												/>
											))}
										</div>
									</div>
									<p className='font-robotol'>{review.review}</p>
								</div>
							))}
						</div>
					</div>
				</div>
				<img src='/assets/UnleashSection/Trophy.svg' alt='Trophy with stars' />
			</div>
		</div>
	);
};

export default UnleashSection;
