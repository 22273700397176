import HeadingTextAnimation from "components/HeadingTextAnimation";
import AccordionItem from "./AccordionItem";

const GetThere = ({ isVisible }: { isVisible: boolean }) => {
	return (
		<div className='h-[1080px] relative flex flex-col items-center justify-center widthClass overflow-x-clip'>
			<div className='w-[1512px] h-[1080px] flex flex-col items-center justify-center '>
				<p className='w-[490px] absolute top-[297px] left-[293px] font-poppinsEb text-[67px] leading-[106%]'>
					<HeadingTextAnimation
						idx={"1"}
						isVisible={isVisible}
						text='How are we'
					/>

					<HeadingTextAnimation
						idx={"2"}
						isVisible={isVisible}
						text='going to get'
					/>

					<HeadingTextAnimation idx={"3"} isVisible={isVisible} text='you' />
				</p>
				<p className='absolute top-[460px] left-[447px] font-poppinsBli text-[79px] leading-[106%] text-squeeze-pink uppercase '>
					<HeadingTextAnimation idx={"3"} isVisible={isVisible} text='There?' extraClass="min-w-[110%]"/>
				</p>
				<img
					className='absolute top-[539px] left-[373px] animate-pulse'
					src='/assets/GetThereSection/large-arrow.svg'
					alt='Large arrow'
				/>
				<img
					className='absolute top-[295px] left-[724px] animate-bounce'
					src='/assets/GetThereSection/small-arrow.svg'
					alt='Small arrow'
				/>
				<div className='absolute top-[462px] left-[780px] right-[302px] space-y-[8px]'>
					<AccordionItem title='Reach out & share your needs' number={"01"}>
						We are available 24/7 to discuss your business's unique needs and
						personal requirements.
					</AccordionItem>
					<AccordionItem title='Get a tailored strategy' number={"02"}>
						Based on your needs, our expert team will develop a bespoke strategy
						using user-centred methods & data-driven decision.
					</AccordionItem>
					<AccordionItem title='Strategies in motion' number={"03"}>
						Watch your business thrive through the performance reports we
						deliver to ensure full transparency.
					</AccordionItem>
				</div>
			</div>
		</div>
	);
};

export default GetThere;
