import React, { useEffect, useRef } from "react";
import gsap from "gsap";
import SplitType from "split-type";
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const TextAnimation = ({
	text,
	isVisible,
	idx,
}: {
	text: string;
	isVisible: boolean;
	idx: string;
}) => {
	const animationTimeline = useRef<gsap.core.Timeline | null>(null);
	const containerRef = useRef<HTMLDivElement | null>(null); // Ref for container

	useEffect(() => {
		if (containerRef.current) {
			// Safely select the element
			const splitElement = containerRef.current.querySelector(
				".split"
			) as HTMLElement;
			if (splitElement) {
				const split = new SplitType(splitElement, { types: "chars" });

				// Add dynamic class to characters
				split.chars?.forEach((char) => {
					char.classList.add(`part-of-${idx}`);
				});

				// Cleanup splitType on component unmount
				return () => {
					split.chars?.forEach((char) => {
						char.classList.remove(`part-of-${idx}`);
					});
				};
			}
		}
	}, [text, idx]);

	useEffect(() => {
		// Create a timeline for the animation
		if (animationTimeline.current) {
			animationTimeline.current.kill(); // Kill any existing animation
		}

		animationTimeline.current = gsap.timeline();

		if (isVisible && containerRef.current) {
			// Trigger animation when isVisible is true
			animationTimeline.current.fromTo(
				containerRef.current.querySelectorAll(
					`.part-of-${idx}`
				) as NodeListOf<HTMLElement>,
				{ yPercent: 130, opacity: 0 },
				{
					yPercent: 0,
					stagger: 0.02,
					ease: "back.out",
					duration: 0.3,
					opacity: 100,
				}
			);
		} else if (containerRef.current) {
			// Reset animation state when not visible
			animationTimeline.current.to(
				containerRef.current.querySelectorAll(
					`.part-of-${idx}`
				) as NodeListOf<HTMLElement>,
				{ yPercent: 130, duration: 0.2 }
			);
		}

		return () => {
			if (animationTimeline.current) {
				animationTimeline.current.kill(); // Clean up animation on component unmount or before new animation starts
			}
		};
	}, [isVisible, idx]);

	return (
		<div
			id={idx}
			className='container overflow-hidden text-white w-fit'
			ref={containerRef}
		>
			<p className='split'>{text}</p>
		</div>
	);
};

export default TextAnimation;
